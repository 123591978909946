<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-card>
          <v-appointment :hn="currentPatient.hn"></v-appointment>
        </v-card>
      </v-col>
      <v-col cols="12" v-else>
        <v-model-table ref="appointmentTable" model-name="Appointments" model-title="รายการนัดหมาย"
          :model-api="['models','Appointment','Appointments']"
          :model-api-search="appointmentQuery"
          api-with="patient"
          :headers="appointmentHeaders" :insertable="false">
          <template v-slot:toolBarItem>
            <v-date-field
              v-model="currentAppointmentDate"
              label="วันที่รับบริการ"
              class="px-1"
              single-line
              hide-details
            ></v-date-field>
            <v-master-field v-model="currentAppointmentActivity" groupKey="$AppointmentActivity" label="กิจกรรมของการนัด" single-line hide-details clearable></v-master-field>
          </template>
          <template v-slot:toolBarSearch>
            <v-text-field
              class="pr-2"
              v-model="currentAppointmentFilter"
              label="Search"
              append-icon="search"
              single-line
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:toolBarButton>
            <v-btn color="primary" @click="exportToCsv" class="mx-1">Export CSV</v-btn>
          </template>
          <template v-slot:item.patient.name_th="props">
            <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
          </template>
          <template v-slot:item.appointmentDateTime="props">
            <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.appointmentType="props">
            <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
          </template>
          <template v-slot:item.appointmentActivity="props">
            <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patientLoader from '@/components/mixins/patientLoader'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import helperPatient from '@/modules/helperPatient'
import { isNil, isEmpty, cloneDeep, isArray  } from 'lodash'
import * as papa from 'papaparse'
import FileSaver from 'file-saver'

export default {
  mixins: [patientLoader],
  data: ()=>({
    currentAppointmentDate: undefined,
    currentAppointmentFilter: undefined,
    currentAppointmentActivity: undefined,
    appointmentHeaders: [
      {
        text: "เวลานัดหมาย",
        value: "appointmentDateTime",
        class: "body-2",
      },
      {
        text: "HN",
        value: "hn",
        class: "body-2",
      },
      {
        text: "ชื่อ-นามสกุล",
        value: "patient.name_th",
        class: "body-2",
      },
      {
        text: "คลินิก",
        value: "clinic.clinicName",
        class: "body-2",
      },
      {
        text: "แพทย์",
        value: "doctor.nameTH",
        class: "body-2",
      },
      {
        text: "ชนิดการนัด",
        value: "appointmentType",
        class: "body-2",
      },
      {
        text: "กิจกรรม",
        value: "appointmentActivity",
        class: "body-2",
      },
    ],
  }),
  computed: {
    appointmentQuery() {
      let result = {
        "whereDate#appointmentDateTime": this.currentAppointmentDate,
      };
      if (!isEmpty(this.currentAppointmentActivity)) result['appointmentActivity'] = this.currentAppointmentActivity
      if (!isNil(this.$config("location").locationCode)) {
        result["clinic$locationCode"] = this.$config("location").locationCode
      }
      if (!isNil(this.currentAppointmentFilter)) {
        result["where#hn#like"] = this.currentAppointmentFilter + "%"
      }
      return result;
    },
  },
  methods: {
    exportToCsv() {
      let exportData = cloneDeep(this.$refs.appointmentTable.apiData[this.$refs.appointmentTable.modelName])
      if (isArray(exportData)) {
        exportData = exportData.map(async (item) => {
          return {
            appointmentDateTime: helperDateTime.parseDateTime(item.appointmentDateTime).format('YYYY-MM-DD H:mm'),
            appointmentDate: helperDateTime.parseDateTime(item.appointmentDateTime).format('YYYY-MM-DD'),
            appointmentTime: helperDateTime.parseDateTime(item.appointmentDateTime).format('H:mm'),
            hn: item.hn,
            name: await helperPatient.name(item.patient.name_th),
            appointmentType: await this.$store.getters.getItemValue(this.$store.getters.getGroupKey("appointmentType"),item.appointmentType),
            appointmentActivity: await this.$store.getters.getItemValue(this.$store.getters.getGroupKey("appointmentActivity"),item.appointmentActivity),
          }
        })
      }
      Promise.all(exportData).then((exportedData)=>{
        let content = papa.unparse(exportedData,{quotes: true})
        var blob = new Blob([String.fromCharCode(0xFEFF),content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, this.$refs.appointmentTable.modelName + '.csv');
      }) 
    }
  },
  beforeMount() {
    this.currentAppointmentDate = helperDateTime.today().format("YYYY-MM-DD")
  }
}
</script>

<style lang="css" scoped>
</style>
